import React, {PureComponent} from 'react';
import coverKids from '../../images/cover-kids.png';
import internet from '../../images/the-Internet.png';
import web from '../../images/web.png';
import web1 from '../../images/web.png';




class canvasIntro extends PureComponent { 

  constructor(props){
     super(props)
   
     this.state = {
        explanationA:this.props.explanationA, 
        explanationB: this.props.explanationB,
        explanationC: this.props.explanationC,
        visualA: this.props.visualA,
        visualB: this.props.visualB  
      };

  }

  componentDidMount(){

  console.log(this.state.explanationA)
  console.log(this.state.visualA)

  function wrapText(context, text, x, y, maxWidth, lineHeight) {
        var words = text.split(' ');
        var line = '';

        for(var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var metrics = context.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > maxWidth && n > 0) {
            context.fillText(line, x, y);
            line = words[n] + ' ';
            y += lineHeight;
          }
          else {
            line = testLine;
          }
        }
        context.fillText(line, x, y);
      }

  this.props.taskStatus(true)

  console.log(this.props)
  
  const img = this.refs.kids;
  const img2 = this.refs.net; 
  const img3 = this.refs.web;
  const img4 = this.refs.web1; 
  let topic = this.props.heading;
  let explanationA = this.props.explanationA;
  let explanationB = this.props.explanationB;
  let explanationC = this.props.explanationC;







  }

  componentDidUpdate(){
  console.log(this.props)
  
  const img = this.refs.kids;
  const img2 = this.refs.net; 
  const img3 = this.refs.web;
  const img4 = this.refs.web1; 
  let topic = this.props.heading;
  let explanationA = this.props.explanationA;
  let explanationB = this.props.explanationB;
  let explanationC = this.props.explanationC;

  }



  render(){
  	return(
      <div className="standard-padding-tb-skin-visual"> 

      <div className="container standard-padding-tb full-height" style={{backgroundImage: `url(${this.state.visualA})`, backgroundSize:"cover", backgroundPosition:"center"}}> 

      <div className="row text-center">

      <div className="col-12">
        <figure>
        <img class="rounded-image" width="10%" src= {this.props.avatar} />
         <figcaption>
         <h5 class="white-text accent-font"> Dear {this.props.fullname}</h5>
        
         </figcaption>
       
        </figure>
       
        </div>

      <div className="col-12">
         
      <img  width="25%" src= {this.state.visualB} />
        </div>
        <div className="row lightPad mildOpacity"> 
        <div className="col-12 col-md-7 white-background text-left lightPad">
          <h5 className='header-font'> Lesson Summary </h5>
        <p class="body-font"> {this.state.explanationA} </p>
        </div>
        <div className="col-12 col-md-5 white-background text-left lightPad">
        <h5 className="header-font"> Lesson Objectives </h5>
        <ul className='showStyle paddingOff'> 
        <li class="body-font"> {this.state.explanationB} </li>
        <li className='body-font'> {this.state.explanationC} </li>
        </ul>
     
        </div>
        </div>
      
       
  
      </div>

      
      </div>

     
   
     </div>
  		)
  }
}

export default canvasIntro