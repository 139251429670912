
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Route, BrowserRouter, Switch } from 'react-router-dom';
import {history} from 'history';
import App from './App'; 
import lab from'./components/lab';
import lab1 from'./components/lab1';
import playgroundLab from './components/playgroundLab';

import portfolioLab from './components/portfolioLab';
import playground from './components/playground';
import assessment from './components/assessments';
import progress from './components/progress';
import Dashboard from './components/dashboard';
import Community from './components/community';
import privacy from './components/privacy';
import terms from './components/terms';
import TheWeb from './components/learningmodules/theweb';
import * as serviceWorker from './serviceWorker';

const routeNetwork = ( 
   <BrowserRouter> 
     <div> 
     <Switch>
        <Route exact path="/" component={App} />




        <Route path="/community" component={Community} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/lab/:topicRef/lessons/:resourceRef" component={lab} />
        <Route path ="/lab/:topicRef/assessments/:taskRef/category/:assessmentRef" component={lab1} />
        <Route path ="/playground/start" component={playgroundLab} />
        <Route path ="/playground/:schoolid/live/:randomint/start" component={playgroundLab} />
        <Route path ="/portfolio" component={portfolioLab} />
       </Switch>
     </div>
     </BrowserRouter>

	)


ReactDOM.render(routeNetwork, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();