import React, {Component} from 'react';
import atcLogo from '../images/SpeechBox.png';

import avatar from '../images/avatar.png';
import attendanceButton from '../images/attendance-button.png';
import '../App.css';





class NavDashboard extends Component { 
 
constructor(props){
   super(props)

 this.state = { 
    candidateFirstName:"", 
    candidateAvatar:""
   }
 
 this.tokenVerification = this.tokenVerification.bind(this)
 this.retrieveCandidateDetails = this.retrieveCandidateDetails.bind(this)
 this.logoutCall = this.logoutCall.bind(this)

}


async componentDidMount(){ 
  await this.retrieveCandidateDetails()
  await this.tokenVerification()

      
}


async tokenVerification(){ 

  const tokenCheck = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/tokenCheck',{ 

    method:"POST",
    headers:{ 
      "content-type" : "application/json"
     },
   mode:"cors",
   credentials:"include"
  
  })
  .then(response => response.json())
  .then(response => {
      if(!response){
        window.location.href = "/"
      }

})

}



async logoutCall(){ 
console.log('i ran')

 const logout = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/logout', { 
         
         method:"GET",
         mode:"cors",
         credentials: "include"

 })
 .then(response => response.json())
 .then(response => {
         console.log(response)
      if(response.logout === true){
          console.log('logout call ran')
         document.location.replace('/')
      }
 })
}



async retrieveCandidateDetails(){ 

    const candidate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/candidate',{ 
      method:"POST",
      headers:{ 
        "content-type" : "application/json"
       },
     mode:"cors",
     credentials:"include"
    
    })
    .then(response => response.json())
    .then(response => {
      console.log(response)

         if(!response){
        return   document.location.replace('/')
      }
      if (response.progress === true || response.progress === false){ 
      this.setState({
        candidateFirstName:response.candidateFirstname,
        candidateAvatar : response.avatar[0]
        

      })
    }

  })
  
}
 

 render(){

  	return(



      <nav className="navbar navbar-expand-lg navbar-light fixed-top text-center" >

  <a className="navbar-brand col-2 margin-off" href="/dashboard/home"><img src={atcLogo} width="75" /></a>


  <ul className="navbar-nav col-md-3  offset-md-7 offset-sm-6 text-right">
    <li className="text-right"> <img className="circular" src={this.state.candidateAvatar}  width="25" />
  
     <button className="btn btn-danger" onClick={this.logoutCall}> Logout {this.state.candidateFirstName} </button>
    </li> 
  </ul>

</nav>


  	)

  }
}



export default NavDashboard